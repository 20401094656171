/* eslint-disable consistent-return */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// React
import { useState, useEffect } from 'react';

// Lib dependencies
import { useSearchParams } from 'react-router-dom';

// Componentes
import LoadingOverlay from '../../shared/components/organisms/LoadingOverlay';

// Services
import CategoryServices from '../../shared/services/category.service';
import ProductServices from '../../shared/services/product.service';

// Configs
import routesPaths from '../../router/configs/routesPaths.configs';

// Hooks
import useRouterNavigation from '../../hooks/useRouterNavigation';

// Others
import useStore from '../../shared/store';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const EntityProvider = (props) => {
  /* ******************************** LOGIC ******************************* */

  const { navigateTo } = useRouterNavigation();

  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  const [searchParams] = useSearchParams();

  const { children } = props;

  const { loadEntity } = useStore();

  const redirectToWholesaler = (wholesaler) => {
    if (wholesaler.name === 'NORDSTROM') {
      setIsMounted(true);
      window.location.href = 'https://www.google.com/';
    }
  };
  useEffect(() => {
    // Get product data from api
    const loadProduct = async (reference) => {
      try {
        // Scan service
        const response = await ProductServices.scan(reference);

        // Check if the component is still mounted
        if (!isMounted && response?.wholesaler) {
          redirectToWholesaler(response.wholesaler);
        }

        loadEntity({
          isLoaded: true,
          type: 'product',
          data: {
            ...response,
            ...response.category,
            reference: response.reference,
            translations: {
              EN: {
                ...response?.translations?.en,
                ...response.category?.translations?.EN,
              },
              FR: {
                ...response?.translations?.fr,
                ...response.category?.translations?.FR,
              },
            },
          },
        });
        setIsLoading(false);
      } catch (error) {
        // Handling api errors

        switch (error?.response?.data?.errorCode) {
          // Scanned product is already certified
          case 9002: {
            // Verify service
            const response = await ProductServices.verify(reference);

            loadEntity({
              isLoaded: true,
              type: 'product',
              data: {
                ...response,
                ...response.category,
                reference: response.reference,
                translations: {
                  EN: {
                    ...response?.translations?.en,
                    ...response.category?.translations?.EN,
                  },
                  FR: {
                    ...response?.translations?.fr,
                    ...response.category?.translations?.FR,
                  },
                },
              },
            });
            setIsLoading(false);
            break;
          }
          // All api errors except 9002
          default:
            navigateTo(routesPaths.notFound);
        }
      }
    };

    let loadingProduct = false;

    const loadCategory = async (reference) => {
      try {
        // Scan service
        const response = await CategoryServices.scan(reference);

        // Check if the component is still mounted
        if (!isMounted && response?.wholesaler) {
          redirectToWholesaler(response.wholesaler);
        }

        loadEntity({
          isLoaded: true,
          type: 'category',
          data: response,
        });

        setIsLoading(false);
      } catch (error) {
        // Handling api errors

        switch (error?.response?.data?.errorCode) {
          // Scanned category is not found
          case 14000: {
            // Check if not already loading a product
            if (!loadingProduct) {
              // Set loadingProduct to true to prevent infinite loops
              loadingProduct = true;
              // Get product data
              await loadProduct(reference);
              // Reset loadingProduct flag after loading product
              loadingProduct = false;
            }
            break;
          }

          // All api errors except 14000
          default:
            navigateTo(routesPaths.notFound);
        }
      }
    };

    const reference = searchParams.get('UID');

    if (reference) {
      loadCategory(reference.trim());
      return;
    }

    setIsLoading(false);
  }, []);

  /* ******************************** RENDERING ******************************* */

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return children;
};

export default EntityProvider;
